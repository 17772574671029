import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const FORGOT_PASSWORD = "forgotpassword";
export const RESET_PASSWORD = "resetpassword";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("auth/local/", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.data[0].messages);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return ApiService.post("users", credentials);
  },
  [FORGOT_PASSWORD](context, payload) {
    return ApiService.post("auth/forgot-password/", payload);
  },
  [RESET_PASSWORD](context, payload) {
    return new Promise(resolve => {
      ApiService.post("auth/reset-password/", payload)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.data[0].messages);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("users/me")
        .then(({ data }) => {
          context.commit("setUserData", data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data[0].messages);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, password, contact_email, contact_phone, business_title, show_contact_email, show_contact_phone, show_business_title, get_notice,expert } = payload;
    const user = { email, contact_email, contact_phone, business_title, show_contact_email, show_contact_phone, show_business_title, get_notice,expert };
    if (password) {
      user.password = password;
    }
    return ApiService.put("users/"+state.user.id, user).then(() => {
      ApiService.get("users/me")
        .then(({ data }) => {
          context.commit("setUserData", data);
        });
    });
  },
  getUserInfo: () => {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("users/me");
    } else {
      return false;
    }
  },
  getUserFromEmail(context, payload) {
    return ApiService.get("users", "?_where[0][email]=" + payload.email);
  },
  updateUserInfo(context, payload) {
    return ApiService.put("users/"+state.user.id, payload);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user.user;
    state.errors = {};
    JwtService.saveToken(user.jwt);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    ApiService.destroyHeader();
    window.localStorage.removeItem('id_role');
  },
  setUserData(state, payload) {
    state.user = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
