import ApiService from "@/core/services/api.service";
import { _ } from "lodash";

const state = {};

const getters = {};

const actions = {
  submitBusinessProfile(context, payload) {
    return ApiService.post("business-profiles", payload);
  },
  submitProfile(context, payload) {
    return ApiService.post("profiles", payload);
  },
  updateProfileInfo(context, payload) {
    const {
      profile_company_name,
      profile_city,
      profile_state,
      profile_contact_email,
      profile_phone,
      instagram,
      linkedin,
      profile_website,
      profile_average_margin,
      profile_mdf_percent,
      profile_map_text,
      profile_map_policy,
      profile_sales_model,
      profile_teaser_description,
      profile_long_description,
      profile_dealer_requirements,
      profile_category,
      profile_dropship,
      promo_text,
      profile_search_content,
      Published
    } = payload;
    if (
      (payload.profile_cover == null || payload.profile_cover == undefined) &&
      (payload.profile_search_img == null ||
        payload.profile_search_img == undefined) &&
      (payload.profile_desc_img == null ||
        payload.profile_desc_img == undefined)
    ) {
      const data = {
        profile_company_name,
        profile_city,
        profile_state,
        profile_contact_email,
        profile_phone,
        instagram,
        linkedin,
        profile_website,
        profile_average_margin,
        profile_mdf_percent,
        profile_map_text,
        profile_map_policy,
        profile_sales_model,
        profile_teaser_description,
        profile_long_description,
        profile_dealer_requirements,
        profile_category,
        profile_dropship,
        promo_text,
        profile_search_content,
        Published
      };
      return ApiService.put("profiles/" + payload.id, data);
    } else {
      const data = new FormData();
      if (payload.profile_cover != null && payload.profile_cover != undefined) {
        data.append("files.profile_cover", payload.profile_cover);
      }
      if (
        payload.profile_search_img != null &&
        payload.profile_search_img != undefined
      ) {
        data.append("files.profile_search_img", payload.profile_search_img);
      }
      if (
        payload.profile_desc_img != null &&
        payload.profile_desc_img != undefined
      ) {
        data.append("files.profile_desc_img", payload.profile_desc_img);
      }
      const text_data = {
        profile_company_name,
        profile_city,
        profile_state,
        profile_contact_email,
        instagram,
        linkedin,
        profile_phone,
        profile_website,
        profile_average_margin,
        profile_mdf_percent,
        profile_map_text,
        profile_map_policy,
        profile_sales_model,
        profile_teaser_description,
        profile_long_description,
        profile_dealer_requirements,
        profile_category,
        profile_dropship,
        promo_text,
        profile_search_content,
        Published
      };
      data.append("data", JSON.stringify(text_data));
      return ApiService.put("profiles/" + payload.id, data);
    }
  },
  updateBusinessProfileInfo(context, payload) {
    const {
      company_name,
      type,
      first_name,
      last_name,
      title,
      duns,
      email,
      phone,
      website,
      addressline1,
      addressline2,
      city,
      state,
      zip,
      store_name,
      company_type,
      state_of_incorporation,
      tax_id,
      have_reseller_form,
      number_of_locations,
      company_desc,
      customer_desc,
      trade_ref1,
      trade_ref2,
      trade_ref3,
      search_content
    } = payload;
    if (
      (payload.reseller_tax_form == null ||
        payload.reseller_tax_form == undefined) &&
      (payload.w9 == null || payload.w9 == undefined) &&
      (payload.storefront == null || payload.storefront == undefined) &&
      (payload.logo == null || payload.logo == undefined)
    ) {
      const data = {
        company_name,
        type,
        first_name,
        last_name,
        duns,
        title,
        email,
        phone,
        website,
        addressline1,
        addressline2,
        city,
        state,
        zip,
        store_name,
        company_type,
        state_of_incorporation,
        tax_id,
        have_reseller_form,
        number_of_locations,
        company_desc,
        customer_desc,
        trade_ref1,
        trade_ref2,
        trade_ref3,
        search_content
      };
      return ApiService.put("business-profiles/" + payload.id, data);
    } else {
      const data = new FormData();
      if (
        payload.reseller_tax_form != null &&
        payload.reseller_tax_form != undefined
      ) {
        data.append("files.reseller_tax_form", payload.reseller_tax_form);
      }
      if (payload.w9 != null && payload.w9 != undefined) {
        data.append("files.w9", payload.w9);
      }
      if (payload.logo != null && payload.logo != undefined) {
        data.append("files.logo", payload.logo);
      }
      if (payload.storefront != null && payload.storefront != undefined) {
        data.append("files.storefront", payload.storefront);
      }
      const text_data = {
        company_name,
        type,
        first_name,
        last_name,
        duns,
        title,
        email,
        phone,
        website,
        addressline1,
        addressline2,
        city,
        state,
        zip,
        store_name,
        company_type,
        state_of_incorporation,
        tax_id,
        have_reseller_form,
        number_of_locations,
        company_desc,
        customer_desc,
        trade_ref1,
        trade_ref2,
        trade_ref3,
        search_content
      };
      data.append("data", JSON.stringify(text_data));
      return ApiService.put("business-profiles/" + payload.id, data);
    }
  },
  submitQuestion(context, payload) {
    return ApiService.post("questions", payload);
  },
  updateQuestion(context, payload) {
    const {
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
      question9,
      question10
    } = payload;
    const data = {
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
      question9,
      question10
    };
    return ApiService.put("questions/" + payload.id, data);
  },
  getQuestionInfo(context, payload) {
    return ApiService.get("questions/" + payload.id);
  },
  submitAnswer(context, payload) {
    return ApiService.post("answers", payload);
  },
  submitApplication(context, payload) {
    return ApiService.post("applications", payload);
  },
  getApplicationById(context, payload) {
    return ApiService.get("applications/" + payload.id);
  },
  getApplicationInfo(context, payload) {
    return ApiService.get(
      "applications",
      "?_where[0][supplier_company]=" +
        payload.supplier_company +
        "&_where[1][seller_company]=" +
        payload.seller_company
    );
  },
  getSellerApplications(context, payload) {
    return ApiService.get(
      "applications",
      "?_start=" +
        payload.start +
        "&_limit=" +
        payload.limit +
        "&_where[0][status]=" +
        payload.status +
        "&_where[1][seller_company]=" +
        payload.seller_company
    );
  },
  getAllSellerApplications(context, payload) {
    return ApiService.get(
      "applications",
      "?_where[0][status]=approved&_where[1][supplier_company]=" +
        payload.supplier_company
    );
  },
  getSupplierApplications(context, payload) {
    return ApiService.get(
      "applications",
      "?_start=" +
        payload.start +
        "&_limit=" +
        payload.limit +
        "&_where[0][status]=" +
        payload.status +
        "&_where[1][supplier_company]=" +
        payload.supplier_company
    );
  },
  getApplicationTotalCount(context, payload) {
    return ApiService.get("applications", "count?" + payload.params);
  },
  updateApplication(context, payload) {
    const { status, reason, crm } = payload;
    const data = { status, reason, crm };

    return ApiService.put("applications/" + payload.id, data);
  },
  getContactInfo(context, payload) {
    return ApiService.get(
      "contacts",
      "?_where[0][supplier_company]=" +
        payload.supplier_company +
        "&_where[1][seller_company]=" +
        payload.seller_company
    );
  },
  getContactTotalCount(context, payload) {
    return ApiService.get("contacts", "count?" + payload.params);
  },
  submitContact(context, payload) {
    return ApiService.post("contacts", payload);
  },
  updateContact(context, payload) {
    const { status } = payload;
    const data = { status };

    return ApiService.put("contacts/" + payload.id, data);
  },
  getCrmById(context, payload) {
    return ApiService.get("crms/" + payload.id);
  },
  getCrmWhere(context, payload) {
    return ApiService.get("crms", payload.where);
  },
  getCrmTotalCount(context, payload) {
    return ApiService.get("crms", "count?" + payload.params);
  },
  submitCrm(context, payload) {
    return ApiService.post("crms", payload);
  },
  updateCrm(context, payload) {
    const {
      partner_status,
      crm_quick_note,
      sales_rep,
      custom_order_note,
      custom_shipping_note,
      credit_limit,
      yearly_sales_goal,
      jan_goal,
      feb_goal,
      mar_goal,
      apr_goal,
      may_goal,
      jun_goal,
      jul_goal,
      aug_goal,
      sep_goal,
      oct_goal,
      nov_goal,
      dec_goal,
      pricelist
    } = payload;
    const data = {
      partner_status,
      crm_quick_note,
      sales_rep,
      custom_order_note,
      custom_shipping_note,
      credit_limit,
      yearly_sales_goal,
      jan_goal,
      feb_goal,
      mar_goal,
      apr_goal,
      may_goal,
      jun_goal,
      jul_goal,
      aug_goal,
      sep_goal,
      oct_goal,
      nov_goal,
      dec_goal,
      pricelist
    };
    return ApiService.put("crms/" + payload.id, data);
  },
  submitNote(context, payload) {
    return ApiService.post("notes", payload);
  },
  getNoteTotalCount(context, payload) {
    return ApiService.get("notes", "count?" + payload.params);
  },
  updateCompanyInfo(context, payload) {
    let id = payload.id;
    delete payload.id;
    console.log(payload);
    if (
      payload.order_logo !== null ||
      payload.store_banner !== null ||
      payload.store_logo !== null
    ) {
      const data = new FormData();
      if (payload.order_logo) {
        data.append("files.order_logo", payload.order_logo);
      } else if (payload.store_banner) {
        data.append("files.store_banner", payload.store_banner);
      } else if (payload.store_logo) {
        data.append("files.store_logo", payload.store_logo);
      }
      delete payload.order_logo;
      delete payload.store_banner;
      delete payload.store_logo;

      data.append("data", JSON.stringify(payload));
      return ApiService.put("companies/" + id, data);
    } else {
      for (let i in payload) {
        console.log(payload[i]);
        if (payload[i] == null) {
          delete payload[i];
        }
      }
      return ApiService.put("companies/" + id, payload);
    }
  },
  submitCompany(context, payload) {
    return ApiService.post("companies", payload);
  },
  getCompanyInfo(context, payload) {
    return ApiService.get("companies/" + payload.id);
  },
  getCompanyTotalCount(context, payload) {
    return ApiService.get("companies", "count?" + payload.params);
  },
  submitUser(context, payload) {
    return ApiService.post("users", payload);
  },
  sendEmail(context, payload) {
    return ApiService.post("email", payload);
  },
  getInviteTotalCount(context, payload) {
    return ApiService.get("invites", "count?" + payload.params);
  },
  submitInvite(context, payload) {
    return ApiService.post("invites", payload);
  },
  getChannels(context, payload) {
    return ApiService.get(
      "channels",
      "?_where[0][supplier]=" + payload.supplier
    );
  },
  getChannelById(context, payload) {
    return ApiService.get("channels/" + payload.id);
  },
  getChannelTotalCount(context, payload) {
    return ApiService.get("channels", "count?" + payload.params);
  },
  removeChannel(context, payload) {
    return ApiService.delete("channels/" + payload.id);
  },
  submitCategory(context, payload) {
    return ApiService.post("categories", payload);
  },
  updateCategory(context, payload) {
    const { name, published, assortment } = payload;
    const data = { name, published, assortment };
    return ApiService.put("categories/" + payload.id, data);
  },
  getCategories(context, payload) {
    return ApiService.get(
      "categories",
      "?_where[0][channel]=" + payload.channel
    );
  },
  getCategoriesWhere(context, payload) {
    return ApiService.get("categories", payload.where);
  },
  removeCategory(context, payload) {
    return ApiService.delete("categories/" + payload.id);
  },
  getCategoryById(context, payload) {
    return ApiService.get("categories/" + payload.id);
  },
  getChannelAccessTotalCount(context, payload) {
    return ApiService.get("channel-accesses", "count?" + payload.params);
  },
  getChannelAccessWhere(context, payload) {
    return ApiService.get("channel-accesses", payload.where);
  },
  getChannelAccess(context, payload) {
    return ApiService.get(
      "channel-accesses",
      "?_where[0][channel]=" +
        payload.channel +
        "&_where[1][seller_company]=" +
        payload.seller_company
    );
  },
  getChannelAccessByChannel(context, payload) {
    return ApiService.get(
      "channel-accesses",
      "?_where[0][channel]=" + payload.channel
    );
  },
  submitChannelAccess(context, payload) {
    return ApiService.post("channel-accesses", payload);
  },
  removeChannelAccess(context, payload) {
    return ApiService.delete("channel-accesses/" + payload.id);
  },
  // Assortment Functions start
  getAllAssortments(context) {
    return ApiService.get("assortments");
  },
  getAssortmentsBySupplier(context, payload) {
    return ApiService.get(
      "assortments",
      "?_where[0][supplier]=" + payload.supplier
    );
  },
  getAssortmentById(context, payload) {
    return ApiService.get("assortments/" + payload.id);
  },
  getAssortmentTotalCount(context, payload) {
    return ApiService.get("assortments", "count?" + payload.params);
  },
  removeAssortment(context, payload) {
    return ApiService.delete("assortments/" + payload.id);
  },
  getAssortmentProductTotalCount(context, payload) {
    return ApiService.get("products", "count?" + payload.params);
  },
  getProductByAssortment(context, payload) {
    return ApiService.get(
      "products",
      "?_where[0][assortments]=" + payload.assortment
    );
  },

  // Assortment functions end

  // Product Functions Start
  updateProductAdditionalImg(context, payload) {
    const data = new FormData();
    data.append("files.additional_image", payload.additional_image);
    const text_data = {};
    data.append("data", JSON.stringify(text_data));
    return ApiService.put("products/" + payload.id, data);
  },
  removeProduct(context, payload) {
    return ApiService.delete("products/" + payload.id);
  },
  getProductTotalCount(context, payload) {
    return ApiService.get("products", "count?" + payload.params);
  },
  getProductById(context, payload) {
    return ApiService.get("products/" + payload.id);
  },
  getProductWhere(context, payload) {
    return ApiService.get("products", payload.where);
  },
  // End Product Functions

  submitItem(context, payload) {
    return ApiService.post("items", payload);
  },
  updateItem(context, payload) {
    const {
      Name,
      sku_id,
      status,
      upc,
      upc2,
      qty,
      instock,
      isbn,
      youtube,
      vimeo,
      short_description,
      long_description,
      clothing_style,
      demo1,
      demo2
    } = payload;
    if (payload.main_image == null || payload.main_image == undefined) {
      const data = {
        Name,
        sku_id,
        status,
        upc,
        upc2,
        qty,
      instock,
        isbn,
        youtube,
        vimeo,
        short_description,
        long_description,
        clothing_style,
        demo1,
        demo2
      };
      return ApiService.put("items/" + payload.id, data);
    } else {
      const data = new FormData();
      data.append("files.main_image", payload.main_image);
      const text_data = {
        Name,
        sku_id,
        status,
        upc,
        upc2,
        isbn,
        qty,
      instock,
        youtube,
        vimeo,
        short_description,
        long_description,
        clothing_style,
        demo1,
        demo2
      };
      data.append("data", JSON.stringify(text_data));
      return ApiService.put("items/" + payload.id, data);
    }
  },
  updateItemAdditionalImg(context, payload) {
    const data = new FormData();
    data.append("files.additional_image", payload.additional_image);
    const text_data = {};
    data.append("data", JSON.stringify(text_data));
    return ApiService.put("items/" + payload.id, data);
  },
  getItemTotalCount(context, payload) {
    return ApiService.get("items", "count?" + payload.params);
  },
  getItemById(context, payload) {
    return ApiService.get("items/" + payload.id);
  },
  getItemWhere(context, payload) {
    return ApiService.get("items", payload.where);
  },
  deleteFile(context, payload) {
    return ApiService.delete("upload/files/" + payload.id);
  },
  removeItem(context, payload) {
    return ApiService.delete("items/" + payload.id);
  },
  submitCart(context, payload) {
    return ApiService.post("carts", payload);
  },
  getCartWhere(context, payload) {
    return ApiService.get("carts", payload.where);
  },
  updateCart(context, payload) {
    const { price, qty } = payload;
    const data = { price, qty };
    return ApiService.put("carts/" + payload.id, data);
  },
  submitPricelist(context, payload) {
    return ApiService.post("pricelists", payload);
  },
  removePricelist(context, payload) {
    return ApiService.delete("pricelists/" + payload.id);
  },
  getPricelist(context) {
    return ApiService.get("pricelists");
  },
  getPricelistById(context, payload) {
    return ApiService.get("pricelists/" + payload.id);
  },
  getPricelistWhere(context, payload) {
    return ApiService.get("pricelists", payload.where);
  },
  updatePricelist(context, payload) {
    const { listname, expiration, noexpiration } = payload;
    const data = { listname, expiration, noexpiration };
    return ApiService.put("pricelists/" + payload.id, data);
  },
  getPricelistItemWhere(context, payload) {
    return ApiService.get("pricelist-items", payload.where);
  },
  getPricelistItemTotalCount(context, payload) {
    return ApiService.get("pricelist-items", "count?" + payload.params);
  },
  submitPricelistItem(context, payload) {
    return ApiService.post("pricelist-items", payload);
  },
  updatePricelistItem(context, payload) {
    const { qty, price } = payload;
    const data = { qty, price };
    return ApiService.put("pricelist-items/" + payload.id, data);
  },
  removePricelistItem(context, payload) {
    return ApiService.delete("pricelist-items/" + payload.id);
  },
  getAddressById(context, payload) {
    return ApiService.get("addresses/" + payload.id);
  },
  getAddressWhere(context, payload) {
    return ApiService.get("addresses", payload.where);
  },
  submitAddress(context, payload) {
    return ApiService.post("addresses", payload);
  },
  updateAddress(context, payload) {
    const {
      company_name,
      first_name,
      last_name,
      address_line1,
      address_line2,
      city,
      state,
      zip_code
    } = payload;
    const data = {
      company_name,
      first_name,
      last_name,
      address_line1,
      address_line2,
      city,
      state,
      zip_code
    };
    return ApiService.put("addresses/" + payload.id, data);
  },
  removeAddress(context, payload) {
    return ApiService.delete("addresses/" + payload.id);
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
