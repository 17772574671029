import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, concat, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import JwtService from "@/core/services/jwt.service";

//prod
var prod_uri = "http://api.mcquasy.com/graphql";
var prod_wuri = "ws://api.mcquays.com/graphql";

// HTTP connection to the API
const httpLink = createUploadLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_STRAPI_API_URL + "/graphql"
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: `Bearer ${JwtService.getToken()}` || null
    }
  });
  return forward(operation);
});

// const wsLink = new WebSocketLink({
//   uri: prod_wuri,
//   options: {
//     reconnect: true
//   }
// })

// const link = split(
//   ({ query }) => {
//     const { kind, operation } = getMainDefinition(query)
//     return kind === 'OperationDefinition' && operation === 'subscription'
//   },
//   wsLink,
//   httpLink
// )

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
  connectToDevTools: true
});

export default apolloClient;
