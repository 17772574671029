import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./core/services/store/auth.module";
import company from "./core/services/store/company.module";
import htmlClass from "./core/services/store/htmlclass.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '/media/sidebar-1.jpg',
    drawer: null,
  },
  modules: {
    auth,
    company,
    htmlClass
   
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
  },
  actions: {

  },
})



