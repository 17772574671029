import Vue from "vue";
import Router from "vue-router";
import ApiService from "@/core/services/api.service";
import store from "./store.js";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/pages",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "Lock",
          path: "lock",
          component: () => import("@/views/pages/Lock")
        },

        {
          name: "reset",
          path: "/reset",
          meta: {
            rule: "isPublic"
          },
          component: () => import("@/view/pages/auth/ResetPassword")
        },

        {
          name: "Login",
          path: "login",
          meta: {
            rule: "isPublic"
          },
          component: () => import("@/views/pages/Login")
        },
        {
          name: "Pricing",
          meta: {
            rule: "isPublic"
          },
          path: "pricing",
          component: () => import("@/views/pages/Pricing")
        },
        {
          name: "Register for Goose",
          path: "register",
          meta: {
            rule: "isPublic"
          },
          component: () => import("@/views/pages/Register")
        },

        {
          name: "forgot password",
          path: "forgot",
          meta: {
            rule: "isPublic"
          },
          component: () => import("@/view/pages/auth/ForgotPassword")
        }
      ]
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/dashboard/Index"),

      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "/dashboard",
          meta: {
            rule: "isPublic"
          },
          component: () => import("@/views/dashboard/Dashboard")
        },

        {
          path: "/account",
          name: "Account",
          meta: {
            rule: "isBuySell"
          },
          component: () => import("@/view/pages/account/Account.vue")
        },
     
        /* Profile INFO
         */

       

        /*         Applications
         */

        

        /* Admin FUNCTIONS
         */

        {
          path: "/admin/addcompany",
          name: "Add Company",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Companyadd.vue")
        },

        {
          path: "/admin/addbrand",
          name: "Add Supplier",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Supplier-add.vue")
        },

        {
          path: "/admin/adduser",
          name: "Add User Admin",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/User-add.vue")
        },
        {
          path: "/admin/buyers",
          name: "Buyer Apps",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Buyerapps.vue")
        },

        {
          path: "/admin/company-list",
          name: "Company List",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Companylist.vue")
        },
        {
          path: "/admin/contactforms",
          name: "Buyer Website Contacts",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Contactforms.vue")
        },
        
        {
          path: "/admin/contactform/:id",
          name: "Buyer website contact forms",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Contactformdetail.vue")
        },
        {
          path: "/admin/buyer-list",
          name: "Buyer List",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Buyerlist.vue")
        },


        {
          path: "/admin/buyer-detail/:id",
          name: "Buyer Detail",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Buyerdetail.vue")
        },

        {
          path: "/admin/seller-detail/:id",
          name: "Seller Detail",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Sellerdetail.vue")
        },



        {
          path: "/admin/sellers",
          name: "Seller Apps",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Sellerapps.vue")
        },

        {
          path: "/admin/edit-bprofile",
          name: "Edit Business Profile",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Bprofile-edit.vue")
        },
        {
          path: "/admin/edit-profile",
          name: "Edit Brand Profile",
          meta: {
            rule: "isAdmin"
          },
          component: () => import("@/view/pages/admin/Profile-edit.vue")
        }

        /* CRM FUNCTIONS
         */



        // Pages

        // Root level
      ]
    },

    {
      path: "*",
      component: () => import("@/views/pages/Index"),
      children: [
        {
          name: "404 Error",
          meta: {
            rule: "isPublic"
          },
          path: "",
          component: () => import("@/views/pages/Error")
        }
      ]
    }
  ]
});
